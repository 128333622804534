<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="login-main">

            <div class="theme-form">
              <h4>Вхід в адмін панель</h4>
              <div class="form-group">
                <label class="col-form-label pt-0">Логін</label>
                <input class="form-control" type="text" required="" [(ngModel)]="email">
              </div>
              <div class="form-group">
                <label class="col-form-label">Пароль</label>
                <input class="form-control" type="password" required="" [(ngModel)]="password">
              </div>
              <div class="form-group form-row mt-3 mb-0">
                <button class="btn btn-primary btn-block" (click)="signin()"><span>Увійти</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>