import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/services/request.service';

type UserFields = 'email' | 'password';

declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public signup: boolean;

  public email = '';
  public password = '';

  public errors = {
    email_required: false,
    password_required: false,
  }

  constructor(private route: Router, public http: HttpClient, public requestService:RequestService) {
  }

  ngOnInit() {
  }

  async signin() {
    await this.requestService.post('adminlogin', {login:this.email, password:this.password}).then(res => {
      localStorage.setItem('admintoken', 'Asd7as78da7D*ASada');
      this.route.navigateByUrl('/push')
    })
  }

}
