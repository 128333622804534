import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequestService {

    public url = 'https://med.cherish.com.ua/api/';

    constructor(public http: HttpClient) { }

    get(path: string) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            this.http.get(url)
                .toPromise()
                .then(res => {
                    resolve(res);
                })
        })
        return promise;
    }

    post(path, data) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            this.http.post(url, data)
                .toPromise()
                .then(res => {
                    resolve(res);
                })
        })
        return promise;
    }

    patch(path, data) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            this.http.patch(url, data)
                .toPromise()
                .then(res => {
                    resolve(res);
                })
        })
        return promise;
    }

    delete(path) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            this.http.delete(url)
                .toPromise()
                .then(res => {
                    resolve(res);
                })
        })
        return promise;
    }
}